.handle:hover {
	transform: scale(1.3);
	transition: transform ease-in-out 100ms, background-color ease-in-out 100ms;
	background-color: var(--drag-handle-active-bg);
}

.handle:active {
	transform: scale(1.3);
	background-color: var(--drag-handle-active-bg);
}
