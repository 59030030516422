.header-container {
	background-color: var(--bg-primary);
	/* border-bottom: solid var(--border-color) 4px; */
	border-left: solid var(--border-color) var(--tree-border-width);
	height: var(--header-height);
	color: white;

	display: flex;
	align-items: center;
}

.header-content {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-left: 5%;
	padding-right: 5%;
	width: 100%;
	height: 100%;
	align-items: center;
}

.toggle-hitboxes {
	margin-left: auto;
	height: 60%;
	background-color: white;
	border: 2px solid var(--border-color);
}

.toggle-hitboxes:active {
	transform: scale(1.1);
}

.toggle-hitboxes:hover {
	background-color: var(--selected-bg);

	color: white;
}

.slider {
	width: 10%;
	margin-left: 20px;
}

.dashboard-btn {
	color: black;
	background-color: white;
	border: white 4px solid;
	border-radius: 5px 5px;
	transition: transform 100ms ease-in;
	margin-left: 10px;
	margin-right: 10px;
}

.dashboard-btn:hover {
	transform: scale(1.1);
}
