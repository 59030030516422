.header-content {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.logout-btn {
	background-color: rgb(67, 67, 67);
	color: white;
	margin-right: 10px;
	border: none;
	width: 100px;
	height: 30px;
	font-weight: bolder;
	transition: background-color 200ms;
}

.logout-btn:hover {
	background-color: rgb(223, 66, 39);
}

.upload-btn {
	background-color: white;
	color: black;
	font-weight: bolder;
	margin-right: 10px;

	width: 100px;
	height: 30px;
	border: white 2px solid;
	transition: border-color 200ms ease-out;
}

.upload-btn:hover {
	border: black 2px solid;
}

.modelgrouplist-container {
	height: 90%;
	width: 100%;
	display: flex;
	flex-direction: column;
	color: white;
	font-weight: bolder;
	align-items: center;
}

.modelgrouplist-header {
	width: 50%;
	margin: 2.5%;
	font-size: 30px;
	padding: 10px;
	background-color: var(--bg-primary);
	filter: contrast(200%);
	-webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
}

.modelgrouplist-list-container {
	width: 90%;
	min-height: 200px;
	/* background-color: var(--bg-primary); */
	background-color: var(--subtitle-bg);
	border: var(--bg-primary) 4px solid;
	border-radius: 15px 15px;
	-webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);

	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
}

.modelgroup-item-container {
	background-color: white;
	color: black;
	width: 95%;
	margin-top: 2%;
	border: 4px solid var(--bg-primary);
	border-radius: 10px 10px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	-webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
}

.modelgroup-item-container::after {
	position: absolute;
	left: 50%;
	width: 5px;
	height: 100%;
	content: "";
	background-color: var(--bg-primary);
}

.share-container {
	display: grid;
	width: 50%;
	grid-template-rows: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	padding: 2px;
}

.share-container > button {
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 3;

	width: 200px;
	height: 30px;
	margin: auto;
	background-color: var(--bg-primary);
	border: var(--bg-primary) 4px solid;

	transition: background-color 100ms;
	color: white;
}

.share-container > button:hover {
	background-color: rgb(31, 172, 237);
	border-color: rgb(31, 172, 237);
}

.modelgroup-right-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	flex: 1;
}

.modelgroup-right-container > button {
	width: 200px;
	height: 30px;
	margin-right: 10px;
	background-color: var(--bg-primary);
	border: var(--bg-primary) 4px solid;

	transition: background-color 100ms;
	color: white;
}

.modelgroup-right-container > button:hover {
	background-color: rgb(31, 172, 237);
	border-color: rgb(31, 172, 237);
}

.input-container {
	grid-column-start: 1;
	grid-column-end: 3;
	width: 200px;
	margin: auto;
	position: relative;
}

.input-container > input {
	appearance: none;
	border: none;
	font-size: 15px;
	outline: none;
}

.input-container > span {
	position: absolute;
	left: 0;
	bottom: -5px;
	width: 100px;
	height: 3px;
	background-color: black;
	transition: width 100ms;
}

.input-container input:focus + span {
	width: 100%;
	background-color: var(--bg-primary);
}

.modelgroup-left-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.modelgroup-left-container > img {
	width: 50px;
	height: auto;
}

.modelgroup-left-container > img:hover {
	transform: scale(1.2);
}

.created-left-item {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 10px;
}

.share-item-container {
	display: flex;
	flex-direction: row;
}

.share-item-container > button {
	color: red;
	border: 1px solid red;
	background-color: white;
	width: 30%;
	height: 30%;
	margin-left: 5px;
}

.share-item-container > button:hover {
	color: white;
	border: 1px solid white;
	background-color: red;
}
