.instance {
	position: absolute;
	background-color: transparent;
}

.model-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
