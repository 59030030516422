.viewer-container {
	background-color: var(--viewer-bg);
	width: 100%;
	height: 100%;
	position: relative;
	overflow: auto;
	border-left: var(--tree-border-width) solid var(--border-color);
}

svg {
	top: 0;
	left: 0;
	position: absolute;
	width: auto;
	height: auto;
	overflow: auto;
}

.viewer-container-wrapper {
	width: 100%;
	height: 100%;
}
