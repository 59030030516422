.header-content {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}

form {
	display: flex;
	flex-direction: column;
}

.form-data {
	display: flex;
	flex-direction: column;
	width: 30%;
	margin: auto;
	margin-top: 10px;
}

.form-data > label {
	text-align: start;
}

form > input[type="submit"] {
	width: 10%;
	height: 30px;
	margin: auto;
	margin-top: 10px;

	font-weight: bold;
	font-size: 15px;
	border: 1px solid var(--bg-primary);
	background-color: white;
	color: var(--bg-primary);

	transition: background-color 100ms ease-in, color 100ms ease-in;
}

form > input[type="submit"]:hover {
	background-color: var(--bg-primary);
	color: white;
}

.dashboard-btn {
	color: black;
	background-color: white;
	border: white 4px solid;
	border-radius: 5px 5px;
	transition: transform 100ms ease-in;
}

.dashboard-btn:hover {
	transform: scale(1.1);
}
