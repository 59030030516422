.app-container-container {
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: row;
}

.app-container-sidebar-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
	/* To resize with acutal pixels using react-resizable */
	flex-grow: 0;
	flex-shrink: 0;
}

.model-tree-handle {
	position: absolute;
	right: 0;
	top: calc(50% - 50px);
	width: 10px;
	height: 100px;
	background-color: var(--drag-handle-bg);
}
