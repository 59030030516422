.model-tree-container {
	background-color: var(--bg-white);
	height: 100%;
	width: 100%;
	min-height: 200px;
	overflow: auto;
	position: relative;
}

.model-tree-handle {
	position: absolute;
	bottom: 0;
	left: calc(50% - 50px);
	width: 100px;
	height: 10px;
	background-color: var(--drag-handle-bg);
}

.model-tree-content {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: flex-start;
	padding-left: 10px;
}

.model-tree-item-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.model-tree-item-pre {
	width: 10px;
	height: 10px;
	border: 2px solid var(--bg-primary);
	margin-right: 10px;
}

.model-tree-item {
}

.model-tree-item:hover {
	text-decoration: underline;
}

.item-selected {
	text-decoration: underline;
}

.pre-selected {
	background-color: var(--selected-bg);
}
