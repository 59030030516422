.container {
	position: absolute;

	width: 300px;
	margin: auto;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	left: 0;
	right: 0;
	text-align: center;
	padding: 20px;

	background-color: rgba(58, 204, 230, 0.9);
	border: rgba(58, 204, 230, 0.9) 4px solid;
	border-radius: 10px 10px;
	z-index: 1000;
	-webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);

	color: white;
}

.container > button {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	font-size: 40px;
	color: white;
	background: none;
	border: none;
}

.container > button:hover {
	transform: scale(1.2);
}
