.details-container {
	width: 100%;
	height: 100%;

	max-width: 500px;
	min-width: 400px;

	background-color: var(--bg-white);
	position: relative;

	border: 4px solid var(--border-color);
}

.details-container:active {
	-webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
}

.details-content {
	display: flex;
	flex-direction: column;
}

.header-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.header-content > button {
	background: none;
	border: none;
	color: white;
	font-size: 30px;
	font-weight: 400;
	margin-right: 5px;
}

.header-content > button:hover {
	background-color: red;
	border-radius: 5px 5px;
}

.ref-header {
	background-color: var(--bg-primary);
	cursor: pointer;
	font-weight: bolder;
	font-size: 20px;
	color: white;
}

.ref-header:hover {
	background-color: var(--selected-bg);
}

.ref-container {
	max-height: 200px;
	overflow-y: auto;
}

.interref-container {
	display: flex;
	flex-direction: column;
	align-content: space-between;
}

.interref-title {
	background-color: var(--subtitle-bg);
	color: var(--subtitle-fg);
	text-align: start;
	padding: 4px;
}

.ref-item {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 40px;
	cursor: pointer;
}

.ref-item:hover {
	background-color: var(--bg-primary);
	color: white;
}

.ref-item:hover img {
	-webkit-filter: invert(100%); /* Safari/Chrome */
	filter: invert(100%);
}

.ref-item-model {
	font-weight: 500;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.ref-item-instance {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: auto;
}

.ref-link {
	margin-left: auto;
}

.ref-item-model-img {
	width: 30px;
	height: auto;
}

.back-nav {
	font-size: 18px !important;
	border-left: 2px solid black !important;
	border-right: 2px solid black !important;
	height: 100% !important;
}

.general-information-title {
	display: flex;
	align-items: center;
	height: 25px;
	justify-content: center;
	background-color: var(--subtitle-bg);
	color: var(--subtitle-fg);
	font-weight: bolder;
}

.general-information-list-container {
	display: flex;
	flex-direction: row;
}

.general-information-titles-container {
	border-right: 1px solid var(--bg-primary);
}

.general-information-item-title {
	text-align: left;
	white-space: nowrap;
	background-color: rgba(95, 172, 208, 0.25);
	border-bottom: 1px solid var(--bg-primary);
	border-right: 1px solid var(--bg-primary);
	font-weight: bolder;
	padding: 4px;
}

.general-information-item-value {
	text-align: left;

	/* background-color: rgba(95, 172, 208, 0.25); */
	border-bottom: 1px solid var(--bg-primary);
	font-weight: bolder;
	padding: 4px;
}

table {
	border-collapse: collapse;
}
