:root {
	--border-color: rgb(92, 92, 92);
	--bg-white: rgb(255, 255, 255);
	--viewer-bg: rgb(255, 255, 255);
	--tree-border-width: 8px;
	--header-height: 50px;
	--drag-handle-bg: rgb(34, 34, 34);
	--drag-handle-active-bg: rgb(2, 127, 200);
	--selected-bg: rgb(103, 200, 245);

	--bg-primary: rgb(34, 128, 172);
	--subtitle-bg: rgb(95, 172, 208);
	--subtitle-fg: white;
}

.App {
	text-align: center;
	width: 100%;
	height: 100%;
	overflow: auto;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
