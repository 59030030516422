.register-link-container {
	display: flex;
	flex-direction: row;
	width: 30%;
	margin: auto;
	align-items: center;
	justify-content: center;
}

.register-link-container > * {
	margin: 10px;
}

form {
	display: flex;
	flex-direction: column;
}

.form-data {
	display: flex;
	flex-direction: column;
	width: 30%;
	margin: auto;
	margin-top: 10px;
}

.form-data > label {
	text-align: start;
}

form > button {
	width: 10%;
	height: 30px;
	margin: auto;
	margin-top: 10px;

	font-weight: bold;
	font-size: 15px;
	border: 1px solid var(--bg-primary);
	background-color: white;
	color: var(--bg-primary);

	transition: background-color 100ms ease-in, color 100ms ease-in;
}

form > button:hover {
	background-color: var(--bg-primary);
	color: white;
}

.form-checkbox {
	display: flex;
	flex-direction: row;

	align-items: center;
	position: relative;
}

.form-checkbox input {
	position: absolute;
	right: 0;
}
.dashboard-btn {
	color: black;
	background-color: white;
	border: white 4px solid;
	border-radius: 5px 5px;
	transition: transform 100ms ease-in;
	margin-left: 10px;
	margin-right: 10px;
}

.header-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
